@tailwind base;
@tailwind components;
@tailwind utilities;

*, :after, :before {
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-sizing: border-box;
}

:focus {
    border: none;
}

:root {
    --text-field-left-slot-width: 8px;
    --text-field-right-slot-width: 8px;
    background-color: white;
    color-scheme: light;
}

@keyframes animation {
    0% {
        opacity: 0;
        transform: translateY(16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

body {
    background: white;
    text-rendering: optimizeLegibility;
}

html {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    font-variation-settings: normal;
    line-height: 1.5;
    tab-size: 4;
}

.animate {
    animation: animation 1s ease-in-out;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
}

.border-slate-4 {
    border-color: color(display-p3 0.882 0.953 0.996/0.111)
}

.border-slate-6 {
    border-color: color(display-p3 0.882 0.949 0.996/0.183);
}

.border-t {
    border-top-width: 1px;
}

.line {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(143, 143, 143, 0.67) 50%, rgba(0, 0, 0, 0) 100%);
}

.rounded-3xl {
    border-radius: 1.5rem;
}

.shadow {
    background: conic-gradient(from 90deg at 50% 50%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 50%), radial-gradient(rgba(200, 200, 200, 0.1) 0%, transparent 80%);
}
